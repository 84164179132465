import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            },
            meta: {
                pageType: 'promotion-landing'
            }
        },

        {
            path: '/meeting',
            name: 'MeetingForm',
            component: () =>
            {
                return import( /* webpackChunkName: "meeting" */ '@/app/pages/MeetingForm' );
            },
            meta: {
                pageType: 'promotion-leadform'
            }
        },

        {
            path: '/gegevens',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            },

            children: [
                {
                    path: '1',
                    name: 'Naw1',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'Naw1' */ '@/app/pages/naw/Naw1' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 1
                    }
                },
                {
                    path: '2',
                    name: 'Naw2',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'Naw2' */ '@/app/pages/naw/Naw2' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 2
                    }
               },
                {
                    path: '3',
                    name: 'Naw3',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'Naw3' */ '@/app/pages/naw/Naw3' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 3
                    }
                }
            ]
        },

        {
            path: '/bedankt',
            name: 'Thanks',
            component: () =>
            {
                return import( /* webpackChunkName: "thanks" */ '@/app/pages/Thanks' );
            },
            meta: {
                pageType: 'promotion-confirmation'
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
